import React from 'react'
import { graphql } from 'gatsby'
import FoTwennyBlazeIt from '../components/FoTwennyBlazeIt/component';
import Helmet from 'react-helmet'
import styled from 'react-emotion'
import Header from '../components/Header'
import Content from '../components/Content'
import Preview from '../components/Preview'
import Footer from '../components/Footer'

const Root = styled.div`
display: flex;
flex-direction: column;
height: 100vh;
`

const Message = styled("div") `
font-family: "Quicksand";
color: #a1dfdd;

  -webkit-animation: TextAnimation 5s infinite;
  -moz-animation: TextAnimation 5s infinite;
  -o-animation: TextAnimation 5s infinite;
animation: TextAnimation 5s infinite;

text-align: center;
margin: 0px auto;

position: absolute;
top: 40%;
margin: 0;
width: 100%;
font-size: 800%;
@media only screen and (max-width: 600px) {
  font-size: 400%;
  padding: 5%;
} 
@media only screen and (min-width: 600px) {
  font-size: 400%;
  padding: 0% 10%;
} 

@keyframes TextAnimation {
  0%{color: #e6ffff; padding-bottom: 0px; font-weight: light;}
  49%{
    color: #e6ffff;
    padding-bottom: 2px;
    padding-left: 5px;
    font-weight: light;
  }
  50%{
    color: #a1dfdd; padding-bottom: 2px; padding-left: 5px; font-weight: light;}
  55%{
    color: #e6ffff; padding-bottom: 0px; font-weight: light;
  }

  98%{color: #e6ffff; padding-bottom: 0px; font-weight: light;}
  100%{color: black; padding-bottom:100px; font-weight: bold;}
}
`

const getParams = (search = '') => {
  return search.replace('?', '').split('&').reduce((params, keyValue) => {
    const [key, value = ''] = keyValue.split('=')
    if (key && value) {
      params[key] = value.match(/^\d+$/) ? +value : value
    }
    return params
  }, {})
}

export default class Index extends React.Component {
  componentDidMount() {
    this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const messages = ["( l i s p c e o )",
                      "'( l i s p c e o )",
                      "'( l i s p c e o )",
                      "lisp-ceo.com",
                      "LISPCEO",
                      "@lisp_ceo",
                      "lisp-ceo",
                      "lisp_ceo",
                      "lisp ceo",
                      "lisp . ceo",
                     ];
    const message = messages[Math.floor(Math.random() * messages.length)];

    return (
        <Root>
        <Helmet
      title="lisp-ceo"
      meta={[
        {
          name: 'description',
          content:
          'Coming in 2019'
        }
      ]}
        >
        <html lang="en" />
        </Helmet>
        <FoTwennyBlazeIt>
        <Message>
        { message }
      </Message>
        </FoTwennyBlazeIt>
        </Root>
    )
  }
}

