import React from 'react'
import styled from 'react-emotion'

const List = styled("div")`
display: grid;
grid-template-columns: [first] 40px [line2] 50px [line3] auto [col4-start] 50px [five] 40px [end];
grid-template-rows: [row1-start] 25% [row1-end] 100px [third-line] auto [last-line];
`

const Item = styled("div")`

`


export default class ChunkyBunchMeenoo extends React.Component {
  render() {
    return (
        <List>
        <Item />
        <Item>Blog</Item>
        <Item />
        </List>
    );
  }
}