import React from 'react'
import styled from 'react-emotion'
import './styles.css'
import ChunkyBunchMeenoo from './ChunkyBunchMeenoo/component';
import PropTypes from 'prop-types'

const FoTwennyOS = styled("div") `
display: ;
flex-direction: column;
flex-flow: space-between;

min-height: 100%;
overflow: hidden;
margin: 0;

display: flex;

background: radial-gradient(ellipse at top, hsla(216.4, 79.4%, 52.4%, 0.97), transparent),
radial-gradient(ellipse at bottom, transparent, hsla(216.8, 55.8%, 61%, 0.5));
background-size: 18% 0.5%;

  -webkit-animation: AnimationName 5s infinite;
  -moz-animation: AnimationName 5s infinite;
  -o-animation: AnimationName 5s infinite;
animation: AnimationName 5s infinite;

@media only screen and (min-width: 800px) {
  font-size: 40px;
}
@media only screen and (min-width: 1200px) {
  font-size: 50px;
}
@keyframes AnimationName { 
  0%{
    background-position:0% 0%;
    background: radial-gradient(ellipse at top, hsla(216.4, 79.4%, 52.4%, 0.97), transparent), radial-gradient(ellipse at bottom, transparent, hsla(216.8, 55.8%, 61%, 0.5)));
  }
  10%{
    background-position:59% 12%;
  }
  50%{
    background-position:19% 88%;
    background: radial-gradient(ellipse at top, hsla(125.7, 86.8%, 35.7%, 0.8), transparent), radial-gradient(ellipse at bottom, transparent, hsla(216.8, 55.8%, 61%, 0.5)));
  }
  99%{
    background-position:0% 100%;
    background: radial-gradient(ellipse at top, hsla(55.7, 75.8%, 82.2%, 0.97), transparent), radial-gradient(ellipse at bottom, transparent, hsla(125.7, 86.8%, 35.7%, 0.8)));
  }
  100%{
    background-position:100% 100%;
    background: radial-gradient(ellipse at top, hsla(216.4, 79.4%, 52.4%, 0.97), transparent), radial-gradient(ellipse at bottom, transparent, hsla(216.8, 55.8%, 61%, 0.5)));
  }
}
`
const Desktop = styled("div") `
  height: 100%;
  width: 100%;
`

const Menu = styled('div')`
  -webkit-animation: TextAnimationLong 45s infinite;
  -moz-animation: TextAnimationLong 45s infinite;
  -o-animation: TextAnimationLong 45s infinite;
  animation: TextAnimationLong 45s infinite;
  @keyframes TextAnimationLong { 
    0%{
      background: transparent;
    }
    99%{
      background: #a1dfdd;
    }
    100%{
      background: black;
    }
  }

  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  border-top: 45px solid #a1dfdd;
  border-left: 3px solid #a1dfdd;
  border-right: 3px solid #a1dfdd;
  border-radius: 15px;
  box-shadow: 0px -60px 400px #a1dfdd;
`

const Item = styled('div')`
  display: flex;
  width: 300px;
  margin: 10%;
  flex:-flow:afte: row wrap;
  justify-content: space-around;
  font-family: "Quicksand";
  font-size: 600%;
  color: #e6ffff;
`

export default class FoTwennyBlazeIt extends React.Component {
  static propTypes = {
    children: PropTypes.node
  }
  render() {
    const { children} = this.props
    return (
        <FoTwennyOS>
          <Desktop>
            {this.props.children}
          </Desktop>
        </FoTwennyOS>
    );
  };
};
